<template>
	<ej-page>
		<ej-mobile-header>
			<ej-toolbar>
				<ej-buttons>
					<ej-back-button></ej-back-button>
				</ej-buttons>

				<ej-title>{{ $route.meta.title }}</ej-title>
			</ej-toolbar>
		</ej-mobile-header>

		<ej-content>
			<ej-breadcrumb>
				<li class="breadcrumb-item">
					<router-link :to="{name: 'orders'}">Pedidos</router-link>
				</li>
				<li class="breadcrumb-item">
					{{ $route.meta.title }}
				</li>
			</ej-breadcrumb>

			<main class="content">
				<div class="container-fluid" v-if="spinner">
					<div class="row d-flex justify-content-center">
						<div class="default-spinner">
							<div class="default-spinner-inner">
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
				</div>

				<div class="container-fluid" v-else>
					<div class="row">
						<div class="col">
							<h5>Informações do {{ orderName }}</h5>
						</div>
					</div>

					<div class="row">
						<div class="form-group col-lg-2 col-12">
							<label>Tipo*</label>
							<ej-select
								v-model.trim="type"
								:options="types"
								track-by="value"
								label="name"
							></ej-select>
						</div>

						<div class="form-group col-lg-2 col-12">
							<label>Data*</label>
							<ej-date-picker
								v-model.trim="order.date"
							></ej-date-picker>
						</div>

						<div class="form-group col-lg-3 col-12">
							<label>Cliente*</label>
							<ej-select
								v-model.trim="customer"
								:options="customers"
								track-by="id"
								label="name"
								@search-change="customerAsyncFind"
								:loading="customerFinding"
							></ej-select>
						</div>

						<div class="form-group col-lg-3 col-12">
							<label>Contato <font-awesome-icon v-tooltip="'Pessoa responsável pelo pedido/orçamento, na qual será feito contato caso necessário.'" :icon="['far', 'question-circle']"></font-awesome-icon></label>
							<ej-select
								v-model.trim="contact"
								:options="contacts"
								track-by="id"
								label="name"
								@search-change="contactAsyncFind"
								:loading="contactFinding"
							></ej-select>
						</div>

						<div class="form-group col-lg-3 col-12">
							<label>Revenda</label>
							<ej-select
								v-model.trim="reseller"
								:options="resellers"
								track-by="id"
								label="name"
								@search-change="resellerAsyncFind"
								:loading="resellerFinding"
							></ej-select>
						</div>

						<div class="form-group col-lg-2 col-12">
							<label>Status</label>
							<ej-select
								v-model.trim="status"
								:options="statuses"
								track-by="value"
								label="name"
							></ej-select>
						</div>

						<div class="form-group col-lg-2 col-12">
							<label>N° OC</label>
							<input
								type="text"
								v-model.trim="order.po"
								class="form-control"
							/>
						</div>

						<div
							v-if="canUpdateSeller"
							class="form-group col-lg-3 col-12"
						>
							<label>Emitente*</label>
							<ej-select
								v-model.trim="seller"
								:options="sellers"
								track-by="id"
								label="name"
								@search-change="sellerAsyncFind"
								:loading="sellerFinding"
							></ej-select>
						</div>

						<div class="form-group col-lg-3 col-12">
							<label>Lista de preços*</label>
							<ej-select
								v-model.trim="price_list"
								:options="price_lists"
								track-by="value"
								label="name"
							></ej-select>
						</div>
					</div>

					<!-- Se for Cabina -->
					<div v-if="_.get(order,'type') === 'cabin'">
						<br>

						<div class="row">
							<div class="col">
								<h5>Informações do Equipamento Agrícola</h5>
							</div>
						</div>

						<div class="row">
							<div class="form-group col-lg-2 col-12">
								<label>Equipamento*</label>
								<ej-select
									v-model.trim="category"
									:options="categories"
									track-by="id"
									label="name"
								></ej-select>
							</div>

							<div class="form-group col-lg-4 col-12">
								<label>Série*</label>
								<ej-select
									v-model.trim="equipment"
									:options="equipments"
									track-by="id"
									label="name"
									@search-change="equipmentAsyncFind"
									:loading="equipmentFinding"
									:disabled="!category"
								></ej-select>
							</div>

							<div class="form-group col-lg-2 col-12">
								<label>Modelo</label>
								<input
									v-model.trim="orderMetaModel['equipamento.modelo']"
									type="text"
									class="form-control"
								>
							</div>

							<div class="form-group col-lg-2 col-12">
								<label>Motor</label>
								<ej-select
									v-model.trim="motor"
									:options="motors"
								></ej-select>
							</div>

							<div class="form-group col-lg-2 col-12">
								<label>Cor*</label>
								<ej-select
									v-model.trim="color"
									:options="colors"
								></ej-select>
							</div>

							<div class="form-group col-lg-1 col-12">
								<label>Ano*</label>
								<input
									v-model.trim="orderMetaModel['equipamento.ano']"
									type="number"
									class="form-control"
								>
							</div>

							<div class="form-group col-lg-2 col-12">
								<label>Possui tanque inferior?*</label>
								<ej-select
									v-model.trim="orderMetaModel['equipamento.tanque_inferior']"
									:options="tankOptions"
								></ej-select>
							</div>

							<div class="form-group col-lg-2 col-12">
								<label>Possui tanque superior?*</label>
								<ej-select
									v-model.trim="orderMetaModel['equipamento.tanque_superior']"
									:options="tankOptions"
								></ej-select>
							</div>

							<div class="form-group col-lg-2 col-12">
								<label>Possui tanque traseiro?*</label>
								<ej-select
									v-model.trim="orderMetaModel['equipamento.tanque_traseiro']"
									:options="tankOptions"
								></ej-select>
							</div>

							<div class="col-lg-3 col-12 d-flex flex-wrap">
								<div class="form-group mr-3">
									<label>É turbinado?</label><br>
									<ej-toggle-button
										v-model.trim="orderMetaModel['equipamento.turbinado']"
										:labels="{checked: 'Sim', unchecked: 'Não'}"
									/>
								</div>

								<div class="form-group mr-3">
									<label>Tem concha?</label><br>
									<ej-toggle-button
										v-model.trim="orderMetaModel['equipamento.concha']"
										:labels="{checked: 'Sim', unchecked: 'Não'}"
									/>
								</div>

								<div class="form-group mr-3">
									<label>É traçado?</label><br>
									<ej-toggle-button
										v-model.trim="orderMetaModel['equipamento.tracado']"
										:labels="{checked: 'Sim', unchecked: 'Não'}"
									/>
								</div>
							</div>

							<div class="col-12" style="display: none">
								<div class="form-group">
									<label>Fotos:</label><br>
									<vue-dropzone
										ref="myVueDropzone"
										id="dropzone"
										:options="dropzoneOptions"
									></vue-dropzone>
								</div>
							</div>
						</div>
					</div>

					<br>

					<!-- Se não for Cabina -->
					<div class="row">
						<div class="col">
							<h5>Itens*</h5>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<table class="desktop-list-content with-sub-table">
								<thead>
									<tr>
										<th style="width: 25%">Produto</th>
										<th style="width: 07%">Qtd.</th>
										<th style="width: 13%">Valor unit.</th>
										<th style="width: 12%">Desconto</th>
										<th style="width: 11%">Op. Fiscal</th>
										<th style="width: 09%">Total</th>
										<th style="width: 07%">Estoq.</th>
										<th style="width: 10%">Custo</th>
										<th style="width: 06%"></th>
									</tr>
								</thead>
								<tbody>
									<tr :key="index" v-for="(item, index) in itemsBrute">
										<td colspan="9">
											<table>
												<tbody>
													<tr class="item">
														<td style="width: 25%">
															<ej-select
																v-if="order.type === 'cabin'"
																:custom-label="productCustomLabel"
																:value="itemsBrute[index].product"
																@select="itemProductSelected(index, $event)"
																@remove="itemProductRemoved(index)"
																:options="compatibleProducts"
																placeholder="Buscar produto por nome"
																:loading="compatibleProductsFinding"
																track-by="id"
																label="name"
															></ej-select>
<!--															v-model.trim="itemsBrute[index].product"-->

															<ej-select
																v-else
																:custom-label="productCustomLabel"
																v-model.trim="itemsBrute[index].product"
																:options="products"
																placeholder="Buscar produto por nome"
																:loading="productsFinding"
																track-by="id"
																label="name"
																@search-change="productAsyncFind"
															></ej-select>
														</td>

														<td style="width: 07%">
															<input type="number" class="form-control" v-model.trim="itemsBrute[index].quantity">
														</td>

														<td style="width: 13%">
															<currency-input v-model.trim="itemsBruteModel['['+index+'].price']" type="text" class="form-control"/>
														</td>

														<td style="width: 12%">
															<currency-input
																v-model.trim="itemsBrute[index].discount"
																type="text"
																class="form-control"
															/>
														</td>

														<td style="width: 11%">
															<ej-select
																v-model="itemsBruteModel['['+index+'].meta.tax_operation']"
																:options="tax_operations"
																track-by="value"
																label="name"
															></ej-select>
														</td>

														<td style="width: 09%">
															{{ toCurrency((itemsBrute[index].price * itemsBrute[index].quantity) - itemsBrute[index].discount) }}
														</td>

														<td style="width: 07%">
															<ej-button class="btn btn-primary" @click="viewStockAndCost(index)">
																<template v-if="itemsBruteModel['['+index+'].loadingStock']">
																	<font-awesome-icon icon="spinner" pulse />
																</template>

																<template v-else>
																	<template v-if="itemsBruteModel['['+index+'].product.stock']">
																		{{ itemsBruteModel['['+index+'].product.stock'] }}&nbsp;<font-awesome-icon :icon="['fas','sync-alt']"></font-awesome-icon>
																	</template>

																	<font-awesome-icon v-else :icon="['fas','sync-alt']"></font-awesome-icon>
																</template>
															</ej-button>
														</td>

														<td style="width: 10%">
															<ej-button class="btn btn-primary" @click="viewStockAndCost(index)">
																<template v-if="itemsBruteModel['['+index+'].loadingCost']">
																	<font-awesome-icon icon="spinner" pulse />
																</template>

																<template v-else>
																	<template v-if="itemsBruteModel['['+index+'].product.cost']">
																		{{ itemsBruteModel['['+index+'].product.cost'] }}&nbsp;<font-awesome-icon :icon="['fas','sync-alt']"></font-awesome-icon>
																	</template>

																	<font-awesome-icon v-else :icon="['fas','sync-alt']"></font-awesome-icon>
																</template>
															</ej-button>
														</td>

														<td style="width: 06%">
															<ej-button class="btn btn-primary" @click="removeItem(index)">
																<font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
															</ej-button>
														</td>
													</tr>

													<tr class="characteristics">
														<td colspan="9">
															<div v-if="_.get(itemsBrute[index],'product.type') === 'cabin'" class="d-flex flex-wrap">
																<div class="form-group mr-4">
																	<label>Cor*</label>
																	<ej-select
																		v-model.trim="itemsBrute[index].meta.cor"
																		:options="colors"
																	></ej-select>
																</div>

																<div class="form-group mr-4">
																	<label>Esguichos*</label><br>
																	<ej-select
																		v-model.trim="itemsBrute[index].meta.esguichos"
																		:options="['Dianteiro', 'Traseiro', 'Dianteiro e traseiro', 'Não']"
																	></ej-select>
																</div>

																<div class="form-group mr-4">
																	<label>Sistema de som*</label><br>
																	<ej-select
																		v-model.trim="itemsBrute[index].meta.sistema_som"
																		:options="['Não', 'Sim, sem rádio', 'Sim, com rádio']"
																	></ej-select>
																</div>

																<div class="form-group mr-4">
																	<label>Filtro*</label><br>
																	<ej-select
																		v-model.trim="itemsBrute[index].meta.filtro"
																		:options="['Anti-Pólen', 'Carvão Ativado']"
																	></ej-select>
																</div>

																<div class="form-group mr-4">
																	<label>I-SIDE</label><br>
																	<ej-toggle-button
																		v-model.trim="itemsBrute[index].meta.iside"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
																	/>
																</div>

																<div class="form-group mr-4">
																	<label>Limpador traseiro</label><br>
																	<ej-toggle-button
																		v-model.trim="itemsBrute[index].meta.limpador_traseiro"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
																	/>
																</div>

																<div class="form-group mr-4">
																	<label>Giroflex</label><br>
																	<ej-toggle-button
																		v-model.trim="itemsBrute[index].meta.giroflex"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
																	/>
																</div>

																<div class="form-group mr-4">
																	<label>Faróis de Milha</label><br>
																	<ej-toggle-button
																		v-model.trim="itemsBrute[index].meta.farois_milha"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
																	/>
																</div>

																<div class="form-group mr-4">
																	<label>Faroís Laterais</label><br>
																	<ej-toggle-button
																		v-model.trim="itemsBrute[index].meta.farois_laterais"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
																	/>
																</div>

																<div class="form-group mr-4">
																	<label>Tomada 12V</label><br>
																	<ej-toggle-button
																		v-model.trim="itemsBrute[index].meta.tomada_12v"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
																	/>
																</div>

																<div class="form-group mr-4">
																	<label>Grade Florestal</label><br>
																	<ej-toggle-button
																		v-model.trim="itemsBrute[index].meta.grade_florestal"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
																	/>
																</div>

																<div class="form-group mr-4">
																	<label>Cortina</label><br>
																	<ej-toggle-button
																		v-model.trim="itemsBrute[index].meta.cortina"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
																	/>
																</div>

																<div class="form-group mr-4">
																	<label>Carregador de Celular USB</label><br>
																	<ej-toggle-button
																		v-model.trim="itemsBrute[index].meta.carregador_usb"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
																	/>
																</div>

																<div class="form-group mr-4">
																	<label>Banco Lateral Articulado ST G3</label><br>
																	<ej-toggle-button
																		v-model.trim="itemsBrute[index].meta.banco_lateral_articulado"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
																	/>
																</div>

																<div class="form-group mr-4">
																	<label>Proteção Florestal de Policarbonato</label><br>
																	<ej-toggle-button
																		v-model.trim="itemsBrute[index].meta.protecao_florestal"
																		:labels="{checked: 'Sim', unchecked: 'Não'}"
																	/>
																</div>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td>{{ toCurrency(priceTotal) }}</td>
										<td>{{ toCurrency(discountTotal) }}</td>
										<td></td>
										<td>{{ toCurrency(grandTotal) }}</td>
										<td></td>
									</tr>
								</tfoot>
							</table>

							<div class="mobile-list-content">
								<div class="row">
									<div class="col-12 col-md-6" :key="index" v-for="(item, index) in itemsBrute">
										<div class="card">
											<div class="card-body">
												<h5 class="card-title">
													<strong>{{ `Item ${index+1}` }}</strong>
												</h5>

												<p class="card-text">
													<strong>Produto:</strong>
													<ej-select
														v-if="order.type === 'cabin'"
														:custom-label="productCustomLabel"
														:value="itemsBrute[index].product"
														@select="itemProductSelected(index, $event)"
														@remove="itemProductRemoved(index)"
														:options="compatibleProducts"
														placeholder="Buscar produto por nome"
														:loading="compatibleProductsFinding"
														track-by="id"
														label="name"
													></ej-select>
													<ej-select
														v-else
														:custom-label="productCustomLabel"
														v-model.trim="itemsBrute[index].product"
														:options="products"
														placeholder="Buscar produto por nome"
														:loading="productsFinding"
														track-by="id"
														label="name"
														@search-change="productAsyncFind"
													></ej-select>
												</p>

												<p class="card-text">
													<strong>Quantidade:</strong>
													<input type="number" class="form-control" v-model.trim="itemsBrute[index].quantity">
												</p>

												<p class="card-text">
													<strong>Preço Unit.:</strong>
													<currency-input v-model.trim="itemsBrute[index].price" type="text" class="form-control"/>
												</p>

												<p class="card-text">
													<strong>Desconto:</strong>
													<currency-input v-model.trim="itemsBrute[index].discount" type="text" class="form-control"/>
												</p>

												<p class="card-text">
													<strong>Op. Fiscal:</strong>
													<ej-select
														v-model="itemsBruteModel['['+index+'].meta.tax_operation']"
														:options="tax_operations"
														track-by="value"
														label="name"
													></ej-select>
												</p>

												<p class="card-text">
													<strong>Estoque: </strong>
													<ej-button class="btn btn-primary">
														<font-awesome-icon :icon="['far', 'eye']"></font-awesome-icon>
													</ej-button>
												</p>

												<p class="card-text">
													<strong>Custo Unit.: </strong>
													<ej-button class="btn btn-primary">
														<font-awesome-icon :icon="['far', 'eye']"></font-awesome-icon>
													</ej-button>
												</p>

												<p class="card-text">
													<strong>Total:</strong>
													{{ toCurrency((itemsBrute[index].price * itemsBrute[index].quantity) - itemsBrute[index].discount) }}
												</p>

												<div v-if="_.get(itemsBrute[index],'product.type') === 'cabin'" class="row">
													<div class="form-group col-12">
														<label>Cor*</label>
														<ej-select
															v-model.trim="itemsBrute[index].meta.cor"
															:options="colors"
														></ej-select>
													</div>

													<div class="form-group col-12">
														<label>Esguichos*</label><br>
														<ej-select
															v-model.trim="itemsBrute[index].meta.esguichos"
															:options="['Dianteiro', 'Traseiro', 'Dianteiro e traseiro', 'Não']"
														></ej-select>
													</div>

													<div class="form-group col-12">
														<label>Sistema de refrigeração*</label><br>
														<ej-select
															v-model.trim="itemsBrute[index].meta.refrigeracao"
															:options="['Ar Condicionado', 'Climatizador']"
														></ej-select>
													</div>

													<div class="form-group col-12">
														<label>Sistema de som*</label><br>
														<ej-select
															v-model.trim="itemsBrute[index].meta.sistema_som"
															:options="['Não', 'Sim, sem rádio', 'Sim, com rádio']"
														></ej-select>
													</div>

													<div class="form-group col-12">
														<label>Filtro*</label><br>
														<ej-select
															v-model.trim="itemsBrute[index].meta.filtro"
															:options="['Anti-Pólen', 'Carvão Ativado']"
														></ej-select>
													</div>

													<div class="form-group col-6 text-center">
														<label>I-SIDE</label><br>
														<ej-toggle-button
															v-model.trim="itemsBrute[index].meta.iside"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>

													<div class="form-group col-6 text-center">
														<label>Limpador traseiro</label><br>
														<ej-toggle-button
															v-model.trim="itemsBrute[index].meta.limpador_traseiro"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>

													<div class="form-group col-6 text-center">
														<label>Giroflex</label><br>
														<ej-toggle-button
															v-model.trim="itemsBrute[index].meta.giroflex"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>

													<div class="form-group col-6 text-center">
														<label>Faróis de Milha</label><br>
														<ej-toggle-button
															v-model.trim="itemsBrute[index].meta.farois_milha"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>

													<div class="form-group col-6 text-center">
														<label>Faroís Laterais</label><br>
														<ej-toggle-button
															v-model.trim="itemsBrute[index].meta.farois_laterais"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>

													<div class="form-group col-6 text-center">
														<label>Tomada 12V</label><br>
														<ej-toggle-button
															v-model.trim="itemsBrute[index].meta.tomada_12v"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>

													<div class="form-group col-6 text-center">
														<label>Grade Florestal</label><br>
														<ej-toggle-button
															v-model.trim="itemsBrute[index].meta.grade_florestal"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>

													<div class="form-group col-6 text-center">
														<label>Cortina</label><br>
														<ej-toggle-button
															v-model.trim="itemsBrute[index].meta.cortina"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>

													<div class="form-group col-6 text-center">
														<label>Carregador de Celular USB</label><br>
														<ej-toggle-button
															v-model.trim="itemsBrute[index].meta.carregador_usb"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>

													<div class="form-group col-6 text-center">
														<label>Banco Lateral Articulado ST G3</label><br>
														<ej-toggle-button
															v-model.trim="itemsBrute[index].meta.banco_lateral_articulado"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>

													<div class="form-group col-6 text-center">
														<label>Proteção Florestal de Policarbonato</label><br>
														<ej-toggle-button
															v-model.trim="itemsBrute[index].meta.protecao_florestal"
															:labels="{checked: 'Sim', unchecked: 'Não'}"
														/>
													</div>
												</div>

												<div class="actions">
													<ej-button class="btn btn-primary" @click="removeItem(index)">
														<font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
													</ej-button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row d-flex justify-content-center">
						<ej-button class="btn btn-secondary hvr-pulse" @click="addItem">
							<font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon> Item
						</ej-button>
					</div>

					<br>

					<div class="row">
						<div class="col">
							<h5>Pagamento</h5>
						</div>
					</div>

					<div class="row">
						<div class="form-group col-lg-3 col-12">
							<label>Forma*</label>
							<ej-select
								v-model.trim="paymentSource"
								:options="paymentSources"
								track-by="value"
								label="name"
							></ej-select>
						</div>

						<div v-if="order.payment_source === 'financing'" class="form-group col-lg-3 col-12">
							<label>Banco</label>
							<input
								v-model.trim="orderMetaModel['financiamento.banco']"
								class="form-control"
								v-uppercase
							/>
						</div>

						<div class="form-group col-lg-2 col-12" v-if="_.get(order,'payment_source') === 'own_resources'">
							<label>Opção</label>
							<ej-select
								v-model.trim="paymentOption"
								:options="paymentOptions"
								track-by="value"
								label="name"
							></ej-select>
						</div>
					</div>

					<div v-if="_.get(order,'payment_source') === 'own_resources' && _.get(order,'payment_option') === 'times'">
						<div class="row">
							<div class="col">
								<h6>Distribuição</h6>
							</div>
						</div>

						<div class="row">
							<div class="col-12 col-md-3" :key="index" v-for="(installment, index) in order.installments">
								<div class="card">
									<div class="card-body">
										<h5 class="card-title">
											<strong>{{ index === 0 ? 'Entrada' : `Parcela ${index}`}}</strong>
										</h5>

										<p class="card-text">
											<strong>Prazo:</strong><br>

											<input type="radio" v-model.trim="order.installments[index].due_type" value="days"> Em dias
											<span v-if="order.installments[index].due_type === 'date' && _.get(order.installments[index], 'due_days')">
												({{ order.installments[index].due_days }})
											</span>
											<br>

											<input type="radio" v-model.trim="order.installments[index].due_type" value="date"> Em data
											<span v-if="order.installments[index].due_type === 'days' && _.get(order.installments[index], 'due_date')">
												({{ moment(order.installments[index].due_date).locale('pt-br').format('DD/MM/YYYY') }})
											</span>
											<br>

											<input
												v-if="order.installments[index].due_type === 'days'"
												type="number"
												v-model.number="order.installments[index].due_days"
												@change="dueDayChanged($event.target.value, index)"
												class="form-control"
												placeholder="Insira a qtd. de dias aqui"
											>
											<ej-date-picker
												v-if="order.installments[index].due_type === 'date'"
												v-model.trim="order.installments[index].due_date"
												@change="dueDateChanged($event, index)"
												placeholder="Insira a data aqui"
											></ej-date-picker>
										</p>

										<p class="card-text">
											<strong>Valor:</strong>
											<currency-input
												v-model.trim="order.installments[index].value"
												class="form-control"
												placeholder="Insira o valor aqui"
											></currency-input>
										</p>

										<div class="actions">
											<button class="btn btn-primary" @click="removeInstallment(index)">
												<font-awesome-icon :icon="['far', 'trash-alt']"></font-awesome-icon>
											</button>
										</div>
									</div>
								</div>
							</div>

							<div class="col-12 col-md-3 d-flex align-items-center justify-content-center">
								<ej-button class="btn btn-secondary hvr-pulse" @click="addInstallment">
									<font-awesome-icon :icon="['fas', 'plus']"></font-awesome-icon> Parcela
								</ej-button>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<h5>Comissão</h5>
						</div>
					</div>

					<div class="row">
						<div class="form-group col-lg-1 col-12">
							<label>Tem comissão?</label><br>
							<ej-toggle-button
								v-model.trim="commissionChecked"
								:labels="{checked: 'Sim', unchecked: 'Não'}"
							/>
						</div>

						<template v-if="commissionChecked">
							<div class="form-group col-lg-3 col-12">
								<label>Indicador*</label>
								<ej-select
									v-model.trim="indicator"
									:options="indicators"
									track-by="id"
									label="name"
									@search-change="indicatorAsyncFind"
									:loading="indicatorFinding"
								></ej-select>
							</div>

							<div class="form-group col-lg-1 col-12">
								<label>Tipo* <font-awesome-icon v-tooltip="'V para valor fixo (decimal) | % para porcentagem (decimal)'" :icon="['far', 'question-circle']"></font-awesome-icon></label><br>
								<ej-toggle-button
									v-model.trim="indicatorValueType"
									:labels="{checked: 'V', unchecked: '%'}"
								/>
							</div>

							<div class="form-group col-lg-2 col-12">
								<label>Valor*</label>
								<currency-input
									v-model.trim="order.indicator_value"
									:currency="indicatorValueType ? 'BRL' : {suffix: ' %'}"
									type="text"
									class="form-control"
								/>
							</div>

							<div v-if="order.status !== 'budget'" class="form-group col-lg-2 col-12">
								<label>Data do Pagamento</label>
								<ej-date-picker
									v-model.trim="indicator_payment_date"
								></ej-date-picker>
							</div>
						</template>
					</div>

					<br>

					<div class="row">
						<div class="col">
							<h5>Entrega</h5>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<h5>Frete</h5>
						</div>
					</div>

					<div class="row">
						<div class="form-group col-lg-2 col-12">
							<label>Tipo de frete</label>
							<ej-select
								track-by="value"
								label="name"
								v-model="tipoFrete"
								:options="shipping_types"
							></ej-select>
						</div>

						<div class="form-group col-lg-3 col-12">
							<label>Transportadora</label>
							<ej-select
								v-model.trim="orderMetaModel['frete.transportadora']"
								:options="carriers"
								track-by="CODCLIFORN"
								label="DESCRCLIFORN"
								@search-change="carrierAsyncFind"
								:loading="carrierFinding"
							></ej-select>
						</div>
					</div>

					<div class="row">
						<div class="col">
							<h5>Montagem</h5>
						</div>
					</div>

					<div class="row">
						<div class="form-group col-lg-4 col-12">
							<div>
								<label style="margin-bottom: 0">Onde?</label><br>
								<small style="line-height: 16px;display: block;margin-bottom: 5px;">(deixe esse campo vazio se a montagem for no mesmo endereço do cliente)</small>
							</div>
							<textarea v-model.trim="orderMeta['montagem']" rows="5" class="form-control"></textarea>
						</div>
					</div>

					<br>

					<div class="row">
						<div class="col">
							<h5>Observações</h5>
						</div>
					</div>

					<div class="row">
						<div class="form-group col-lg-4 col-12">
							<label>Observações (Esta observação é de visualização <span style="font-weight: 900">PÚBLICA</span>!)</label>
							<textarea rows="5" class="form-control" v-model.trim="order.ps"></textarea>
						</div>

						<div class="form-group col-lg-4 col-12">
							<label style="color: red">Observações internas (Esta observação é de visualização <span style="font-weight: 900">INTERNA</span>!)</label>
							<textarea rows="5" class="form-control" v-model.trim="order.ps_internal"></textarea>
						</div>
					</div>

					<br>

					<div class="row">
						<div class="col">
							<h5>Outros</h5>
						</div>
					</div>

					<div class="row">
						<div v-if="order.status === 'budget'" class="form-group col-lg-2 col-12">
							<label>Validade do orçamento* <font-awesome-icon v-tooltip="'Em dias'" :icon="['far', 'question-circle']"></font-awesome-icon></label>
							<input
								type="number"
								v-model.trim="order.validity"
								placeholder="Em dias"
								class="form-control"
							/>
						</div>

						<div v-else class="form-group col-lg-3 col-12">
							<label>Previsão de Entrega <font-awesome-icon v-tooltip="'Texto livre, pode ser informar o que você quiser'" :icon="['far', 'question-circle']"></font-awesome-icon></label>
							<input
								type="text"
								v-model.trim="order.delivery_forecast"
								class="form-control"
							/>
						</div>
					</div>

					<br>

					<div class="row">
						<div class="col">
							<h5>Confirmação*</h5>
						</div>
					</div>

					<div class="row">
						<form class="form-group col">
							<label>Estou ciente que qualquer informação preenchida incorretamente neste pedido/orçamento, poderá me trazer custos extras.</label><br>
							<ej-toggle-button
								v-model.trim="confirmation"
								:labels="{checked: 'Sim', unchecked: 'Não'}"
							/>
						</form>
					</div>

					<br>

					<div class="row d-flex justify-content-center">
						<ej-button
							@click="salvar"
							class="btn btn-secondary hvr-pulse"
							:disabled="saving"
						>
							<strong>Salvar  <font-awesome-icon :icon="['fas', 'cog']" spin v-if="saving"></font-awesome-icon></strong>
						</ej-button>
					</div>
				</div>
			</main>
		</ej-content>
	</ej-page>
</template>

<script>
	//Mixins
	import FetchingMixin from "@/mixins/FetchingMixin";
	import Swal from "sweetalert2";
	import {exibeErro, sanitize} from "@/helpers";
	import CurrencyMixin from "@/mixins/CurrencyMixin";

	//Aux
	const _ = require('lodash');
	import moment from 'moment';
	import EjButton from "@/components/EjButton";
	import EjBackButton from "@/components/EjBackButton";
	import EjToggleButton from "@/components/EjToggleButton";
	import {vueSet} from "vue-deepset";
	import Push from "push.js";

	//Dropzone
	import vue2Dropzone from 'vue2-dropzone'
	import 'vue2-dropzone/dist/vue2Dropzone.min.css'

	export default {
		name: 'OrderEdit',
		components: {
			EjToggleButton,
			EjBackButton,
			EjButton,
			vueDropzone: vue2Dropzone
		},
		data() {
			return {
				confirmation: false,
				loadingOrder: false,
				loadingIndicators: false,
				loadingCategories: false,
				loadingContacts: false,
				saving: false,
				commission: false,
				dropzoneOptions: {
					addRemoveLinks: true,
					url: 'https://httpbin.org/post',
					thumbnailWidth: 150,
					maxFilesize: 0.5,
					headers: { "My-Awesome-Header": "header value" }
				},

				order: {
					date: undefined,
					type: undefined,
					po: undefined,
					price_list: undefined,
					status: 'new',
					customer_id: undefined,
					customer: {
						name: undefined
					},
					validity: undefined,
					indicator: {
						name: undefined
					},
					indicator_value: undefined,
					indicator_value_type: undefined,
					indicator_payment_date: undefined,
					contact: {
						name: undefined
					},
					payment_source: undefined,
					payment_option: undefined,
					// seller: {
					// 	id: this.$store.state.auth.user.id,
					// 	name: this.$store.state.auth.user.name
					// },
					seller_id: this.$store.state.auth.user.id,
					indicator_id: undefined,
					contact_id: undefined,
					reseller: {
						name: undefined
					},
					installments: undefined,
					items: [],
					ps: undefined,
					ps_internal: undefined,
					delivery_forecast: undefined
				},

				/* Criei o meta fora do order por causa do problema de reatividade do Vue,
				no final eu incluo ele pra salvar */
				orderMeta: {},

				paymentSources:[
					{name:'Recursos próprios',value:'own_resources'},
					{name:'Financiamento',value:'financing'}
				],
				paymentOptions: [
					{name:'A vista',value:'cash'},
					{name:'A prazo',value:'times'}
				],
				price_lists: [
					{name:'Consumidor final',value:'3'},
					{name:'Revendas',value:'4'}
				],
				categories: [],
				compatibleProducts: [],
				products: [],
				tax_operations: [
					{name:'Venda normal',value:'1'},
					{name:'Frete',value:'10'},
				],
				types: [
					{name:'Cabina',value:'cabin'},
					{name:'Outro',value:'other'},
				],
				itemsBrute: [],
				colors: [
					'AMARELO',
					'AZUL',
					'AZUL FORD',
					'AZUL NH',
					'BRANCO',
					'DOURADO',
					'LARANJA',
					'PRATA',
					'VERDE',
					'VERDE KAWASAKI',
					'VERMELHO',
				],
				shipping_types: [
					{name: "Pago (CIF)", value: 0},
					{name: "A pagar (FOB)", value: 2},
					{name: "Sem frete", value: 9}
				],
				motors: [
					'MWM',
					'VALTRA',
				],
				tankOptions: [
					'Não',
					'Sim, de ferro',
					'Sim, de plástico'
				],
				compatibleProductsFinding: false,
				productsFinding: false,

				customers: [],
				customerFinding: false,

				sellers: [],
				sellerFinding: false,

				resellers: [],
				resellerFinding: false,

				indicators: [],
				indicatorFinding: false,

				contacts: [],
				contactFinding: false,

				equipments: [],
				equipmentFinding: false,

				carriers: [],
				carrierFinding: false,
			}
		},

		watch: {
			"orderMeta.equipamento.cor": {
				handler: function(newValue) {
					/* Evita que o código seja executado no arranque, quando o valor de this.order é definido,
					se não tiver esse if aqui dá merda */
					if (!this.loadingOrder && this.order.type === 'cabin') {
						this.itemsBrute[0].meta.cor = newValue;
					}
				}
			},

			"order.payment_source"(newValue) {
				switch (newValue) {
					case 'financing':
						this.order.payment_option = undefined;
						this.order.installments = [];

						break;

					case 'own_resources':
						this.$delete(this.orderMeta, 'financiamento');
						break;
				}

				if (!this.loadingOrder)
					this.zzz();
			},

			"order.payment_option": {
				handler: function(newValue) {
					switch (newValue) {
						case 'cash':
							this.order.installments = [];
							break;
					}
				}
			},

			"order.type": {
				handler: function(newValue) {
					/* Evita que o código seja executado no arranque, quando o valor de this.order é definido,
					se não tiver esse if aqui dá merda */
					if (!this.loadingOrder) {
						vueSet(this, 'itemsBrute', []);

						if (newValue === 'other')
							this.$delete(this.orderMeta, 'equipamento');

						if (newValue)
							//Já cria um item automaticamente para facilitar a vida do usuário
							this.itemsBrute.push({
								product: undefined,
								meta: {},
								quantity: 1,
								discount: 0,
								price: undefined,
							});
					}
				}
			},

			"order.status"() {
				if (!this.loadingOrder)
					this.zzz();
			}
		},

		computed: {
			canUpdateSeller() {
				return this.$store.state.auth.permissions['create_orders_in_any_case']
					|| this.$store.state.auth.permissions['create_orders_with_status_budget_for_anyone']
					|| this.$store.state.auth.permissions['create_orders_with_status_new_for_anyone']
					|| this.$store.state.auth.permissions['update_orders_in_any_case']
					|| this.$store.state.auth.permissions['update_order_data_except_status_for_anyone']
					|| this.$store.state.auth.permissions['update_orders_with_status_budget_to_new_for_anyone']
					|| this.$store.state.auth.permissions['update_orders_with_status_new_to_released_for_anyone']
					|| this.$store.state.auth.permissions['update_orders_with_status_released_to_rejected_for_anyone']
					|| this.$store.state.auth.permissions['update_orders_with_status_rejected_to_released_for_anyone']
					|| this.$store.state.auth.permissions['update_orders_with_status_rejected_to_new_for_anyone']
					|| this.$store.state.auth.permissions['update_orders_with_status_released_to_launched_for_anyone']
					|| this.$store.state.auth.permissions['update_orders_with_status_launched_to_produced_for_anyone']
					|| this.$store.state.auth.permissions['update_orders_with_status_produced_to_shipped_for_anyone']
					|| this.$store.state.auth.permissions['update_orders_with_status_shipped_to_delivered_for_anyone'];
			},

			tipoFrete: {
				get() {
					return this.shipping_types.find((t) => t.value == _.get(this.orderMeta, 'frete.tipo'));
				},
				set(tipo) {
					this.orderMetaModel['frete.tipo'] = tipo ? tipo.value : null;
				}
			},

			indicator_payment_date: {
				get() {
					return this.order.indicator_payment_date;
				},
				set(date) {
					this.order.indicator_payment_date = date ? date : null;
				}
			},

			orderName() {
				return this.order.status === 'budget' ? 'Orçamento' : 'Pedido';
			},

			orderMetaModel() {
				return this.$deepModel(this.orderMeta);
			},

			itemsBruteModel() {
				return this.$deepModel(this.itemsBrute);
			},

			statuses() {
				//CANCELADO
				const canceled = {'name': 'CANCELADO', 'value': 'canceled'};
				if (
					!this.$store.state.auth.permissions['create_orders_in_any_case']
					&& !this.$store.state.auth.permissions['update_orders_in_any_case']
					&& !this.$store.state.auth.permissions['cancel_orders_for_anyone']
					&& !this.$store.state.auth.permissions['cancel_budget_or_new_orders_for_anyone']
					&& !this.$store.state.auth.permissions['cancel_budget_or_new_orders_for_yourself']
				)
					canceled["$isDisabled"] = true;

				//ENTREGUE
				const delivered = {'name': 'ENTREGUE', 'value': 'delivered'};
				if (
					!this.$store.state.auth.permissions['create_orders_in_any_case']
					&& !this.$store.state.auth.permissions['update_orders_in_any_case']
					&& !this.$store.state.auth.permissions['update_orders_with_status_shipped_to_delivered_for_anyone']
				)
					delivered["$isDisabled"] = true;

				//ENTREGUE
				const launched = {'name': 'LANÇADO', 'value': 'launched'};
				if (
					!this.$store.state.auth.permissions['create_orders_in_any_case']
					&& !this.$store.state.auth.permissions['update_orders_in_any_case']
					&& !this.$store.state.auth.permissions['launch_orders_in_any_case']
					&& !this.$store.state.auth.permissions['update_orders_with_status_released_to_launched_for_anyone']
				)
					launched["$isDisabled"] = true;

				//LIBERADO
				const released = {'name': 'LIBERADO', 'value': 'released'};
				if (
					!this.$store.state.auth.permissions['create_orders_in_any_case']
					&& !this.$store.state.auth.permissions['update_orders_in_any_case']
					&& !this.$store.state.auth.permissions['release_orders_in_any_case']
					&& !this.$store.state.auth.permissions['release_orders_with_status_new_or_rejected_for_anyone']
					&& !this.$store.state.auth.permissions['update_orders_with_status_new_to_released_for_anyone']
					&& !this.$store.state.auth.permissions['update_orders_with_status_rejected_to_released_for_anyone']
				)
					released["$isDisabled"] = true;

				//NOVO
				const _new = {'name': 'NOVO', 'value': 'new'};
				//ORÇAMENTO
				const budget = {'name': 'ORÇAMENTO', 'value': 'budget'};

				//PRODUZIDO
				const produced = {'name': 'PRODUZIDO', 'value': 'produced'};
				if (
					!this.$store.state.auth.permissions['create_orders_in_any_case']
					&& !this.$store.state.auth.permissions['update_orders_in_any_case']
					&& !this.$store.state.auth.permissions['produce_orders_in_any_case']
					&& !this.$store.state.auth.permissions['produce_orders_with_status_launched_for_anyone']
					&& !this.$store.state.auth.permissions['update_orders_with_status_launched_to_produced_for_anyone']
				)
					produced["$isDisabled"] = true;

				//REJEITADO
				const rejected = {'name': 'REJEITADO', 'value': 'rejected'};
				if (
					!this.$store.state.auth.permissions['create_orders_in_any_case']
					&& !this.$store.state.auth.permissions['update_orders_in_any_case']
					&& !this.$store.state.auth.permissions['reject_orders_in_any_case']
					&& !this.$store.state.auth.permissions['reject_orders_with_status_new_or_released_for_anyone']
					&& !this.$store.state.auth.permissions['update_orders_with_status_new_to_rejected_for_anyone']
					&& !this.$store.state.auth.permissions['update_orders_with_status_released_to_rejected_for_anyone']
				)
					rejected["$isDisabled"] = true;

				return [
					canceled,
					delivered,
					launched,
					released,
					_new,
					budget,
					produced,
					rejected,
				];
			},

			grandTotal() {
				return this.itemsBrute.reduce((ac, item) => ac += (item.quantity * item.price) - item.discount,0);
			},

			priceTotal() {
				return this.itemsBrute.reduce((ac, item) => ac += (item.quantity * item.price),0);
			},

			discountTotal() {
				return this.itemsBrute.reduce((ac, item) => ac += item.discount,0);
			},

			customer: {
				get() {
					const customer_id = _.get(this.order, 'customer.id');
					return customer_id ? this.customers.find((c) => c.id == customer_id) : null;
				},

				set(customer) {
					this.order.customer = customer;
					this.order.customer_id = customer ? customer.id : null;
				}
			},

			equipment: {
				get() {
					return _.get(this.orderMeta.equipamento, 'serie');
				},

				set(equipment) {
					if (equipment)
						this.compatibleProductAsyncFind(equipment);

					vueSet(this.orderMeta,'equipamento.serie', equipment);
				}
			},

			color: {
				get() {
					if (this.order.type === 'cabin')
						return _.get(this.orderMeta.equipamento, 'cor');
					else
						return null;
				},

				set(color) {
					vueSet(this.orderMeta, 'equipamento.cor', color);
				}
			},

			status: {
				get() {
					const status = _.get(this.order,'status');
					return this.statuses.find((s) => s.value === status);
				},

				set(status) {
					this.order.status = status ? status.value : null;
				}
			},

			price_list: {
				get() {
					const price_list = _.get(this.order,'price_list');
					return this.price_lists.find((s) => s.value == price_list);
				},

				set(price_list) {
					this.order.price_list = price_list ? price_list.value : null;
				}
			},

			motor: {
				get() {
					if (this.order.type === 'cabin')
						return _.get(this.orderMeta.equipamento, 'motor');
					else
						return null;
				},

				set(motor) {
					vueSet(this.orderMeta, 'equipamento.motor', motor);
				}
			},

			commissionChecked: {
				get() {
					return this.commission;
				},

				set(value) {
					if (!value) {
						this.commission = false;
						this.order.indicator_id = undefined;
						this.order.indicator_value = undefined;
						this.order.indicator_value_type = undefined;
						this.order.indicator_payment_date = undefined;
					} else
						this.commission = true;
				}
			},

			contact: {
				get() {
					const contact_id = _.get(this.order, 'contact_id');
					return contact_id ? this.contacts.find((c) => c.id == contact_id) : null;
				},

				set(contact) {
					this.order.contact_id = contact.id;
				}
			},

			indicator: {
				get() {
					const indicator_id = _.get(this.order, 'indicator_id');
					return indicator_id ? this.indicators.find((c) => c.id == indicator_id) : null;
				},

				set(indicator) {
					this.order.indicator_id = indicator ? indicator.id : null;
				}
			},

			seller: {
				get() {
					const seller_id = _.get(this.order, 'seller_id');
					return seller_id ? this.sellers.find((c) => c.id == seller_id) : null;
				},

				set(seller) {
					if (this.canUpdateSeller)
						this.order.seller_id = seller.id;
					else
						this.order.seller_id = this.$store.state.auth.user.id;
				}
			},

			reseller: {
				get() {
					const reseller_id = _.get(this.order, 'reseller.id');
					return reseller_id ? this.resellers.find((c) => c.id == reseller_id) : null;
				},

				set(reseller) {
					this.order.reseller = reseller;
					this.order.reseller_id = reseller ? reseller.id : null;
				}
			},

			paymentSource: {
				get() {
					const paymentSource = _.get(this.order, 'payment_source');
					return paymentSource ? this.paymentSources.find((c) => c.value == paymentSource) : null;
				},

				set(payment_source) {
					this.order.payment_source = (payment_source) ? payment_source.value : null;
				}
			},

			paymentOption: {
				get() {
					const paymentOption = _.get(this.order, 'payment_option');
					return paymentOption ? this.paymentOptions.find((c) => c.value == paymentOption) : null;
				},

				set(payment_option) {
					this.order.payment_option = (payment_option) ? payment_option.value : null;
				}
			},

			type: {
				get() {
					const type = _.get(this.order, 'type');
					return type ? this.types.find((t) => t.value == type) : null;
				},

				set(type) {
					this.order.type = (type) ? type.value : null;
				}
			},

			indicatorValueType: {
				get() {
					const type = _.get(this.order, 'indicator_value_type');
					return type === 'fixed_value';
				},

				set(type) {
					this.order.indicator_value_type = (type) ? 'fixed_value' : 'percentage';
				}
			},

			category: {
				get() {
					if (this.order.type === 'cabin')
						return _.get(this.orderMeta.equipamento, 'categoria');
					else
						return null;
				},

				set(type) {
					this.equipment = null;
					this.equipments = [];

					vueSet(this.orderMeta, 'equipamento.categoria', type);
				}
			},

			spinner() {
				return this.loadingOrder || this.loadingCategories || this.loadingSellers;
			}
		},

		mixins: [
			FetchingMixin,
			CurrencyMixin
		],

		methods: {
			viewStockAndCost(index) {
				const vm = this;

				if (!vm.itemsBruteModel['['+index+'].product']) {
					Swal.fire(
						'Atenção!',
						'Você deve selecionar um produto primeiro!',
						'warning'
					);
					return;
				}

				vm.itemsBruteModel['['+index+'].loadingCost'] = true;
				vm.itemsBruteModel['['+index+'].loadingStock'] = true;

				this.$store.dispatch(`products/get`, vm.itemsBruteModel['['+index+'].product.id'])
					.then(function (response) {
						vm.itemsBruteModel['['+index+'].product.stock'] = response.data.stock !== null && response.data.stock !== undefined ? response.data.stock.toString() : null;
						vm.itemsBruteModel['['+index+'].product.cost'] = vm.toCurrency(response.data.cost);
					})
					.catch(function (error) {
						// handle error
						exibeErro(error);
					})
					.then(function () {
						// always executed
						vm.itemsBruteModel['['+index+'].loadingStock'] = false;
						vm.itemsBruteModel['['+index+'].loadingCost'] = false;
					});
			},

			productCustomLabel({ code, name }) {
				return code ? `${code} - ${name}` : name;
			},

			//todo
			zzz() {
				if (this.order.status === 'budget') {
					if (this.order.payment_source === 'financing')
						this.order.validity = this.$store.state.auth.settings['custom_due_financed_orders'];
					else
						this.order.validity = this.$store.state.auth.settings['custom_due_budgets'];

				} else
					this.order.validity = undefined;

				if (this.order.status === 'budget')
					Push.create('A validade do orçamento foi atualizada automaticamente!', {
						silent: true
					});
			},

			itemProductSelected(index, value) {
				this.itemsBrute[index].product = value;
				this.itemsBrute[index].price = this.itemsBrute[index].product.price;

				if (!this.itemsBrute[index].quantity)
					this.itemsBrute[index].quantity = 1;

				if (!this.itemsBrute[index].discount)
					this.itemsBrute[index].discount = 0;
			},
			itemProductRemoved(index) {
				this.itemsBrute[index].product = null;
			},

			diffInDays(from, to) {
				const a = moment(from);
				const b = moment(to);
				return a.diff(b, 'days');
			},
			sumDaysToDate(days, date) {
				return moment(date).add(days, 'day');
			},
			dueDayChanged(value, index) {
				this.order.installments[index].due_date = this.sumDaysToDate(value, this.order.date).format('YYYY-MM-DD');
			},
			dueDateChanged(value, index) {
				this.order.installments[index].due_days = this.diffInDays(value, this.order.date);
			},

			addItem() {
				this.itemsBrute.push({
					meta: {},
					product: undefined,
					discount: 0,
					price: undefined,
					quantity: 1,
				});
			},

			removeItem(index) {
				this.$delete(this.itemsBrute, index);
			},

			removeInstallment(index) {
				this.$delete(this.order.installments, index);
			},

			addInstallment() {
				this.order.installments = this.order.installments ?? [];

				this.order.installments.push({
					due_days: undefined,
					due_date: undefined,
					due_type: 'days',
					value: undefined,
				});
			},

			salvar() {
				if (!this.confirmation) {
					Swal.fire(
						'Atenção!',
						'Você precisa concordar com os termos para poder salvar.',
						'warning'
					);
					return;
				}

				if (this.commission && !this.order.indicator_id) {
					Swal.fire(
						'Atenção!',
						'Você não informou o indicador.',
						'warning'
					);
					return;
				}

				const vm = this;

				this.operation = this.$route.name === 'orders.edit' ? 'edit' : 'create';

				this.order.items = this.itemsBrute.map(function(item) {
					return {
						..._.pick(item, ['quantity','price','discount','meta']),
						product_id: _.get(item.product,'id')
					}
				});
				vueSet(this.order, 'meta', this.orderMeta);

				vm.saving = true;
				switch (this.operation) {
					case 'create':
						this.$store.dispatch(`orders/post`, sanitize(this.order))
							.then(function() {
								Swal.fire(
									'Sucesso!',
									`${vm.orderName} criado com sucesso!`,
									'success'
								).then(function(result) {
									if (result.isConfirmed)
										window.location = '/orders';
								});
							})
							.catch(function (error) {
								// handle error
								exibeErro(error);
							})
							.then(function () {
								// always executed
								vm.saving = false;
							});
						break;

					case 'edit':
						this.$store.dispatch(`orders/patch`, {
							id: this.order.id,
							data: sanitize(this.order)
						})
							.then(function() {
								Swal.fire(
									'Sucesso!',
									`${vm.orderName} atualizado com sucesso!`,
									'success'
								);
							})
							.catch(function (error) {
								// handle error
								exibeErro(error);
							})
							.then(function () {
								// always executed
								vm.saving = false;
							});
						break;
				}
			},

			productAsyncFind(keyword) {
				const vm = this;

				this.productsFinding = true;

				this.$store.dispatch(`products/index`, {
					"filter[products.name_code]": keyword,
					"filter[types]": ['other'],
					"filter[products.salable]": true,
				})
					.then(function (response) {
						vm.products = response.data.data;
					})
					.catch(function (error) {
						// handle error
						exibeErro(error);
					})
					.then(function () {
						// always executed
						vm.productsFinding = false;
					});
			},

			equipmentAsyncFind(keyword) {
				const vm = this;
				this.equipmentFinding = true;

				this.$store.dispatch(`products/index`, {
					"filter[products.name]": keyword,
					"filter[products.type]": 'equipment',
					"filter[categories]": [this.category.id]
				})
					.then(function (response) {
						vm.equipments = response.data.data.map((c) => _.pick(c,['id','brand','name','code']));
					})
					.catch(function (error) {
						// handle error
						exibeErro(error);
					})
					.then(function () {
						// always executed
						vm.equipmentFinding = false;
					});
			},

			async customerAsyncFind(keyword) {
				this.customerFinding = true;

				try {
					let response = await this.getPeople({
						"filter[people.name]": keyword,
						"filter[people.customer]": true
					});
					this.customers = response.data.data;

				} catch (e) {
					exibeErro(e);
				}

				this.customerFinding = false;
			},

			async resellerAsyncFind(keyword) {
				this.resellerFinding = true;

				try {
					let params = {"filter[people.reseller]": true};

					if (keyword !== undefined)
						params["filter[people.name]"] = keyword;

					let response = await this.getPeople(params);
					this.resellers = response.data.data;

				} catch (e) {
					exibeErro(e);
				}

				this.resellerFinding = false;
			},

			async sellerAsyncFind(keyword) {
				this.sellerFinding = true;

				try {
					let params = {"filter[people.seller]": true};

					if (keyword !== undefined)
						params["filter[people.name]"] = keyword;

					let response = await this.getPeople(params);
					this.sellers = response.data.data;

				} catch (e) {
					exibeErro(e);
				}

				this.sellerFinding = false;
			},

			async carrierAsyncFind(keyword) {
				this.carrierFinding = true;

				try {
					let response = await this.$store.dispatch(`carriers/index`, {keyword: keyword});
					this.carriers = response.data;

				} catch (e) {
					exibeErro(e);
				}

				this.carrierFinding = false;
			},

			async indicatorAsyncFind(keyword) {
				this.indicatorFinding = true;

				try {
					let response = await this.getPeople({"filter[people.name]": keyword});
					this.indicators = response.data.data;

				} catch (e) {
					exibeErro(e);
				}

				this.indicatorFinding = false;
			},

			async contactAsyncFind(keyword) {
				this.contactFinding = true;

				try {
					let response = await this.getPeople({"filter[people.name]": keyword});
					this.contacts = response.data.data;

				} catch (e) {
					exibeErro(e);
				}

				this.contactFinding = false;
			},

			getPeople(params = {}) {
				return this.$store.dispatch(`people/index`, params);
			},

			async compatibleProductAsyncFind(equipment) {
				const vm = this;

				try {
					vm.compatibleProductsFinding = true;
					const response = await this.$store.dispatch(`products/index`, {
						"filter[compatible_with]": equipment.id
					});

					vm.compatibleProducts = response.data.data;

					/* Se só há 1 produto compatível, então já seleciona ele automaticamente. Desabilita
					* a execução no arranque (quando os dados de this.order estiverem sendo definidos, se não dá merda. */
					if (vm.compatibleProducts.length === 1 && !vm.loadingOrder) {
						vueSet(vm, 'itemsBrute', [{
							meta: {},
							quantity: 1,
							discount: 0,
							product: vm.compatibleProducts[0],
							price: !vm.itemsBrute[0].price ? vm.compatibleProducts[0].price : null
						}]);
					}

				} catch(error) {
					exibeErro(error);
				}

				vm.compatibleProductsFinding = false;
			}
		},

		mounted() {
			const vm = this;

			this.loadingCategories = true;
			this.$store.dispatch(`categories/index`, {
				"sort": "categories.name"
			})
				.then(function (response) {
					vm.categories = response.data.data.map((c) => _.pick(c,['id','name']));
				})
				.catch(function (error) {
					// handle error
					exibeErro(error);
				})
				.then(function () {
					// always executed
					vm.loadingCategories = false;
				});

			//Carrega lista completa de vendedores
			vm.sellerAsyncFind();

			if (this.$route.name === 'orders.edit') {
				this.loadingOrder = true;
				window.axios
					.get(`/orders/${vm.$route.params.id}`)
					.then(async function (response) {
						vm.order = Object.assign(vm.order, response.data);
						vueSet(vm, 'orderMeta', response.data.meta);
						delete vm.order.price;
						delete vm.order.total;
						delete vm.order.discount;

						vueSet(vm, 'itemsBrute', response.data.items ?? []);

						const carrier = _.get(response.data.meta, 'frete.transportadora');
						if (carrier)
							vm.carriers.push(carrier);

						if (vm.order.type === 'cabin') {
							vm.equipments = [vm.orderMeta.equipamento.serie];
							await vm.compatibleProductAsyncFind(vm.orderMeta.equipamento.serie);
						}

						const customer = _.get(vm.order,'customer');
						if (customer) {
							vm.customers.push(vm.order.customer);
							vm.order.customer_id = customer.id;
						}

						const reseller = _.get(vm.order,'reseller');
						if (reseller) {
							vm.resellers.push(vm.order.reseller);
							vm.order.reseller_id = reseller.id;
						}

						const contact = _.get(vm.order,'contact');
						if (contact) {
							vm.contacts.push(vm.order.contact);
							vm.order.contact_id = contact.id;
						}

						const indicator = _.get(vm.order,'indicator');
						if (indicator) {
							vm.indicators.push(vm.order.indicator);
							vm.order.indicator_id = indicator.id;
						}

						if (!_.isEmpty(indicator))
							vm.commission = true;
					})
					.catch(function (error) {
						// handle error
						exibeErro(error);
					})
					.then(function () {
						// always executed
						vm.loadingOrder = false;
					});
			}
		}
	}
</script>

<style scoped>
	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) {
		.content {
			padding: 10px 20px 20px 20px !important;
		}

		table {
			table-layout: fixed;
			width: 100%;
		}

		tr.characteristics > td {
			padding-top: 10px;
		}

		table.desktop-list-content:not(.vdpTable) {
			width: 100%;
			border-collapse: separate;
			border-spacing: 0 15px;
		}

		table.desktop-list-content > tbody > tr > td {
			user-select: auto;
			background-image: linear-gradient(to right, #a7a7a7 33%, rgba(255,255,255,0) 0%);
			background-position: bottom;
			background-size: 15px 1px;
			background-repeat: repeat-x;
			padding: 10px 0;
		}
	}

	.vue-dropzone {
		border-radius: 10px;
	}
</style>
